@import "./mixins";
@import "@angular/cdk/overlay-prebuilt.css";
@import "./fonts";
// @import './reset'; //?? bootstrap reboots replace this
@import "./colors";
@import "./vars";
@import "./helpers";
@import "./components/main.scss";

.disable-li-style {
  list-style: none;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.buttons-container {
  position: relative;
  z-index: 20;
  visibility: hidden;
  pointer-events: none;
}

.list-el-container:hover .buttons-container {
  visibility: visible;
  pointer-events: all;
}

.list-el-container .user-list-el-badget p {
  transition: 0.2s ease;
}

.list-el-container:hover .user-list-el-badget p {
  color: #000 !important;
}

.list-el-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  min-width: 120px;
  padding: 2px 6px 2px 6px;
  border-radius: 6px;
  background-color: $green;

  span {
    color: #fff;
  }

  &--orange-alt {
    background-color: $orange-alt;
  }
}

.colors {
  position: absolute;
  top: 0;
  right: -10px;
  height: 100%;
  width: 500px;

  div {
    height: 100%;
    width: 100%;
    border-radius: 0 10px 10px 0;
  }
}

@media screen and (min-width: 1250px) {
  .responsive-text {
    white-space: nowrap;
  }
}

// Evita grid overflow breaks with paddings
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  padding: 0;
  margin: 0 !important;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0;
}

.card {
  border: 0;
}

.btn {
  &:hover {
    background-color: #374052 !important;
  }

  &.text-red {
    background-color: transparent !important;
  }
}

// Modals
.modal-content {
  border-radius: 0.5rem !important;
}

.modal-header {
  border: none !important;
  padding-bottom: 0px !important;
}

.modal-body > ul.list-dots {
  margin-left: 16px !important;

  & > li:before {
    content: "\2022";
    color: #de377c;
    font-weight: 700;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

.modal-footer {
  padding-top: 0px !important;
  border-top: none !important;
}

button.modal-footer--cta {
  @extend .btn;
  @extend .bg-red;

  background: #de377c !important;
  padding: 6px 26px !important;
  font-size: 18px !important;
  color: white !important;
}

section.filtersDateRange .input-group.before > .dropdown-menu {
  transform: translate3d(-73px, -1px, 0px) !important;
}

section.filtersDateRange .input-group.after > .dropdown-menu {
  transform: translate3d(-1px, -1px, 0px) !important;
}

ul.ngx-pagination > li.current {
  background: #de377c !important;
  border-radius: 6px;
}

ul.ngx-pagination > li a:hover {
  border-radius: 6px;
}

ul.ngx-pagination > li {
  margin-right: 6px;
}

ul.ngx-pagination > li:nth-last-child(2) {
  margin-right: 0px;
}

ul.ngx-pagination > li.pagination-next {
  margin-right: 0px;
}

ul.ngx-pagination > li.current > span > span:last-child {
  color: white !important;
}

input:focus-visible,
input:focus {
  outline: #de377dc2 auto 1px !important;
}

select:focus-visible,
select:focus {
  outline: #de377dc2 auto 1px !important;
}

textarea:focus-visible,
textarea:focus {
  outline: #de377dc2 auto 1px !important;
}

.form-control:focus {
  border-color: initial !important;
  box-shadow: initial !important;
  outline: #de377dc2 auto 1px !important;
}

.form-select:focus {
  border-color: initial !important;
  box-shadow: initial !important;
  outline: #de377dc2 auto 1px !important;
}

.btn:focus-visible {
  border-color: initial !important;
  box-shadow: initial !important;
  outline: #de377dc2 auto 1px !important;
}

.btn:disabled {
  background-color: #777777 !important;
}

.user-list-el-badget {
  color: #fff;
  width: fit-content;
  font-style: italic;
  border-radius: 6px;
  font-weight: 600;
  padding: 4px 8px;
}

.close-modal-icon {
  position: absolute;
  top: -30px;
  right: -30px;
  width: 24px;
  height: 24px;
}

.bg-badge-yellow {
  background: linear-gradient(90deg, #f5c17d 0%, #fda22c 100%);
}

.bg-badge-gray {
  background: linear-gradient(90deg, #d9d9d9 0%, #9e9e9e 100%);
}

.user-list-el-badget--yellow {
  background: linear-gradient(90deg, #f5c17d 0%, #fda22c 100%);
}

.user-list-el-badget--gray {
  background: linear-gradient(90deg, #d9d9d9 0%, #9e9e9e 100%);
}

@mixin button() {
  height: 40px;
  padding: 5px 20px;
  max-width: 100px;
  border-radius: 8px;
  border: none;
  font-weight: 500;
}

.button-submit {
  @include button();
  color: #fff;
  background-color: #00caa8;
}

.button-dismiss {
  @include button();
  color: #de377c;
  background-color: #fff;
}

.button-dismiss-outlined {
  @include button();
  color: #de377c;
  background-color: #fff;
  border: 1px solid rgb(197, 197, 197);
}

.button-delete {
  @include button();
  color: #de377c;
  background-color: transparent;
}

.modal-container {
  padding: 50px;
  border-radius: 6px;
  background-color: #fff;
}

.modal-container-rounded {
  padding: 50px 70px;
  border-radius: 20px;
  background-color: #fff;
}
