@use "sass:math";
// bg-$color | text-$color helper
@include syntax-colors($white : #fff,
  $neutral : #FCFCFC,
  $grey-dark :#4D4D4D,
  $grey :#707070,
  $grey-alt :#94969B,
  $grey-light :#E4E6EB,
  $grey-extra-light :#f9f9f9,
  $blue-dark :#292E37,
  $blue : #374052,
  $blue-light : #078CC7,
  $blue-extra-light : #90AAED,
  $green : #00CAA8,
  $orange : #FF9E68,
  $orange-alt : #F5C17D,
  $red : #DE377C,
  $red-alt : #FF3131,
  $purple : #9E3678,
  $purple-light : #C029C0,
  $sucess : #66bb6a,
  $error : #f44336,
  $warn : #ffa726);

// OPACITY HELPER
@for $i from 0 to 101 {
  .font-#{$i} {

    font-size: $i * 1px;
  }
}

@for $i from 0 to 101 {
  .borda-#{$i} {
    border-radius: $i * 1px !important;
  }
}

@for $i from 0 to 11 {
  .opacity-#{$i} {

    // opacity: calc(#{$i} / 10);
    opacity: math.div($i, 10);
  }
}

@for $i from 0 to 101 {
  .w-#{$i} {
    width: $i * 1%;
  }

  .h-#{$i} {
    height: $i * 1%;
  }
}

// WiTHDS AND HEIGHTS by PX
@for $i from 0 to 321 {
  .w-#{$i}px {
    width: $i * 1px;
  }

  .h-#{$i}px {
    height: $i * 1px;
  }
}

* {
  @extend .text-blue;
}

.img-fluid {
  max-width: 100%;
  height   : auto;
}

.font-header {
  // font-family: $font-secondary_b;
  // font-weight: bold;
  // font-size  : 26px;
  font: normal normal bold 26px/35px $font-secondary_eb;
}

.font-btn {
  @extend .font-header;
  // font          : normal normal bold 26px/35px $font-secondary_b !important;
  letter-spacing: 1px;
}

.font-regular {
  font-family: $font-secondary;
  font-weight: 400;
  font-size  : 14px;
}

.link {
  text-decoration: none;
}

.input {
  height        : 45px;
  background    : #FFFFFF 0% 0% no-repeat padding-box;
  border        : 1px solid #C5C5C5;
  border-radius : 5px;
  padding       : 13px 18px;
  font          : normal normal normal 14px/19px $font-secondary;
  letter-spacing: .75px;

  &[type="text"],
  &[type="password"] {}

  &[type="checkbox"] {
    // background-color: #bada55 !important;
    margin       : 0;
    background   : #FFFFFF 0% 0% no-repeat padding-box;
    border       : 1px solid #C5C5C5;
    border-radius: 13px;
  }

  // COLOR PICKER
  &[type="color"] {
    // -webkit-appearance: none;
    border-radius: 100%;
    padding      : 0;
    width        : 25px;
    height       : 25px;
    overflow     : hidden;
  }

  &[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &[type="color"]::-webkit-color-swatch {
    border          : none;
    // border-radius: 10px;
  }
}

textarea,
select {
  background    : #FFFFFF 0% 0% no-repeat padding-box;
  border        : 1px solid #C5C5C5;
  border-radius : 5px;
  padding       : 13px 18px;
  font          : normal normal normal 14px/19px $font-secondary;
  letter-spacing: .75px;
}

select {
  // Allow arrows drops
  background-color   : #fff;
  background-image   : url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat  : no-repeat;
  background-position: right 0.75rem center;
  background-size    : 16px 12px;
}

.video-container {
  position      : relative;
  padding-bottom: 56.25%; //?? 16:9
  height        : 0;

  // width       : 90%;
  display        : flex;
  align-items    : center;
  justify-content: center;

  .video-js {
    position: absolute;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
  }

  .vjs-big-play-button {
    position : absolute;
    top      : 50%;
    left     : 50%;
    transform: translate(-50%, -50%);
  }
}

.page {
  padding  : 12px;
  max-width: 1600px;
  width    : 100%;
  margin   : 0 auto;

  &--full {
    @extend .page;
    padding  : 0;
    max-width: unset;
  }
}

@media screen and (min-width: 768px) {
  .page {
    padding: 64px;
  }
}

p {
  margin-bottom: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

// custom scroll

.scroll-x {
  overflow-x: scroll;
}

.scroll-x-primary::-webkit-scrollbar {
  width : 4px;
  height: 8px;
}

/* Track */
.scroll-x-primary::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll-x-primary::-webkit-scrollbar-thumb {
  background   : #C4C4C4;
  border-radius: 4px;
}


/* Handle on hover */
.scroll-x-primary::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

// truncate text

.truncate {
  overflow     : hidden;
  white-space  : nowrap;
  text-overflow: ellipsis;
}

// Font Weight
.font-thin {
  font-weight: 100
}

.font-extralight {
  font-weight: 200
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.font-extrabold {
  font-weight: 800
}

.font-black {
  font-weight: 900
}

// widths
.w-fit {
  width: fit-content;
}

// Max With
.max-w-0 {
  max-width: 0rem;
}

.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-min {
  max-width: min-content;
}

.max-w-max {
  max-width: max-content;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

// Responsive helpers css

@media (min-width: 768px) {

  //should be a mixin
  // widths
  .md\:w-fit {
    width: fit-content !important;
  }

  .md\:w-auto {
    width: auto !important;
  }

  .md\:w-100 {
    width: 100% !important;
  }

  // margin top
  .md\:mt-0 {
    margin-top: 0px !important;
  }

  .md\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .md\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .md\:mt-3 {
    margin-top: 1rem !important;
  }

  .md\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .md\:mt-5 {
    margin-top: 3rem !important;
  }

  // margin right
  .md\:me-0 {
    margin-right: 0px !important;
  }

  .md\:me-1 {
    margin-right: 0.25rem !important;
  }

  .md\:me-2 {
    margin-right: 0.5rem !important;
  }

  .md\:me-3 {
    margin-right: 1rem !important;
  }

  .md\:me-4 {
    margin-right: 1.5rem !important;
  }

  .md\:me-5 {
    margin-right: 3rem !important;
  }

  // flex justify-content
  .md\:justify-content-start {
    justify-content: start !important;
  }

  .md\:justify-content-center {
    justify-content: center !important;
  }

  .md\:justify-content-end {
    justify-content: end !important;
  }

  // cols
  .md\:col {
    flex: 1 0 0%;
  }
}

.overflow-y-scroll {
  overflow-y: scroll;
}
